<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        If a theoretical compound has a
        <stemble-latex content="$\Delta\text{H}_{\text{vap}}=$" />
        <number-value :value="hVap" unit="\text{kJ/mol}" />
        and a
        <stemble-latex content="$\Delta\text{S}_{\text{vap}}=$" />
        <number-value :value="sVap" unit="\text{J K}^{-1}\text{mol}^{-1}," />
        determine the boiling point for this compound.
      </p>

      <calculation-input
        v-model="inputs.Tb"
        prepend-text="$\text{T}:$"
        append-text="$\text{K}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'Question284',
  components: {
    CalculationInput,
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Tb: null,
      },
    };
  },
  computed: {
    hVap() {
      return this.taskState.getValueBySymbol('enthalpy').content;
    },
    sVap() {
      return this.taskState.getValueBySymbol('entropy').content;
    },
  },
};
</script>
